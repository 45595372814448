export const CONTAINER_MAX_WIDTH = 1160
export const CONTAINER_MAX_WIDTH_PX = `${CONTAINER_MAX_WIDTH}px`

export const CONTENT_WIDTH = 752
export const CONTENT_WIDTH_PX = `${CONTENT_WIDTH}px`

export const NEW_AUTH_WIDTH = 400
export const NEW_AUTH_WIDTH_PX = `${NEW_AUTH_WIDTH}px`

export const HEADER_HEIGHT = 64
export const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`

export const CONTENT_HEIGHT = `calc(100vh - ${HEADER_HEIGHT_PX})`

export const SIDEBAR_WIDTH = 290
export const SIDEBAR_WIDTH_PX = `${SIDEBAR_WIDTH}px`
