import { Link, Text, VStack } from "@chakra-ui/layout"
import { SidebarContentProps } from "app/common/layouts/MainLayout/Sidebar/MainSidebar"
import { Link as BlitzLink, Routes } from "blitz"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { useUserFlags } from "../../../../utils/misc/userFlags"

const StandardSidebar: FC<SidebarContentProps> = ({ onDrawerClose }) => {
  const { t } = useTranslation()
  const { IS_STAFF } = useUserFlags()

  return (
    <VStack align="start" spacing={2} mt={10} ps={4} w="100%">
      <BlitzLink passHref href={Routes.Dashboard()}>
        <Link
          onClick={onDrawerClose}
          fontWeight="bold"
          _hover={{ textDecoration: "none" }}
          color="expertBlue.500"
        >
          <Text fontSize="lg">
            {IS_STAFF ? t("labels.sidebarMainMenu") : t("labels.sidebarSubjects")}
          </Text>
        </Link>
      </BlitzLink>
    </VStack>
  )
}

export default StandardSidebar
