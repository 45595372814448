import { Head as BHead } from "blitz"
import { FC } from "react"
interface Props {
  title?: string
  description?: string
  pageTitleLast?: boolean
}

const Head: FC<Props> = ({ title, description, pageTitleLast }) => {
  const titleStr = pageTitleLast ? `arcensus Portal | ${title}` : `${title} | arcensus Portal`
  return (
    <BHead>
      <title>{title ? titleStr : "arcensus Portal"}</title>

      {description && <meta name="description" content={description} />}

      {/*From https://realfavicongenerator.net/*/}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0f345a" />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta name="theme-color" content="#0f345a" />
    </BHead>
  )
}

export default Head
