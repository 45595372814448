import { FamilyRelation, Gender, YesNoUnknown } from "db"
import { TFunction } from "react-i18next"

export const getYesNoOptions = (t: TFunction) => {
  return [
    { label: t("labels.yes"), value: "true" },
    { label: t("labels.no"), value: "false" },
  ]
}

export const getGenderOptions = (t: TFunction) => {
  return [
    { label: t("labels.male"), value: Gender.Male },
    { label: t("labels.female"), value: Gender.Female },
    { label: t("labels.other"), value: Gender.Other },
  ]
}

export const getFamilyRelationOptions = (t: TFunction) => {
  return Object.values(FamilyRelation).map((value) => ({
    label: t(`labels.familyRelation.${value}`),
    value,
  }))
}

export const getYesNoUnknownOptions = (t: TFunction) => [
  { label: t("labels.yes"), value: YesNoUnknown.Yes },
  { label: t("labels.no"), value: YesNoUnknown.No },
  { label: t("labels.unknown"), value: YesNoUnknown.Unknown },
]

export const addressKeys = ["addressLine1", "addressCity", "addressPostCode", "addressCountry"]

export const familyHistoryKeys = [
  "cancer",
  "longTermTreatment",
  "mentalHealthIssues",
  "heartProblems",
  "visionLoss",
  "hearingLoss",
  "stroke",
  "miscarriage",
] as const
export const getFamilyHistoryFields = (
  t: TFunction
): Record<typeof familyHistoryKeys[number], { label: string; summary: string }> => {
  return {
    cancer: {
      label: t("labels.cancerQuestion"),
      summary: t("labels.cancer"),
    },

    longTermTreatment: {
      label: t("labels.longTermTreatmentQuestion"),
      summary: t("labels.longTermTreatment"),
    },

    mentalHealthIssues: {
      label: t("labels.mentalHealthIssuesQuestion"),
      summary: t("labels.mentalHealthIssues"),
    },

    heartProblems: {
      label: t("labels.heartProblemsQuestion"),
      summary: t("labels.heartProblems"),
    },

    visionLoss: {
      label: t("labels.visionLossQuestion"),
      summary: t("labels.visionLoss"),
    },

    hearingLoss: {
      label: t("labels.hearingLossQuestion"),
      summary: t("labels.hearingLoss"),
    },

    stroke: {
      label: t("labels.strokeQuestion"),
      summary: t("labels.stroke"),
    },

    miscarriage: {
      label: t("labels.miscarriageQuestion"),
      summary: t("labels.miscarriage"),
    },
  }
}

export const ALLOWED_FILE_EXTENSIONS = [
  "pdf",
  "png",
  "jpg",
  "jpeg",
  "bmp",
  "gif",
  "mp4",
  "mov",
  "avi",
  "webm",
]

export const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/bmp",
  "image/gif",
  "video/mp4",
  "video/quicktime",
  "video/x-msvideo",
  "video/webm",
]

export const CONSENT_FORM_LINK = "/documents/Arcensus_Consent_20230530_V4.1.pdf"
export const USER_GUIDE_LINK = "/documents/User Guide - Arcensus Portal_v1.8.pdf"
export const ADMIN_GUIDE_LINK = "/documents/Portal_InternalUserGuide_v1.11.pdf"
