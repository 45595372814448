import { Text, TextProps } from "@chakra-ui/react"

const getEnvNameToDisplay = (): string | undefined => {
  if (typeof window === undefined) {
    return undefined
  }

  const hostname = window.location.hostname

  if (hostname === "localhost") {
    return "local"
  }

  if (hostname.startsWith("dev-portal")) {
    return "staging"
  }

  if (hostname.startsWith("demo-portal")) {
    return "demo"
  }

  return undefined
}

/**
 * This will show a text like "staging" or "local" depending on the host name.
 * In production it shouldn't render anything.
 */
export const EnvironmentIndicator = (props: TextProps) => {
  const envName = getEnvNameToDisplay()

  if (envName) {
    return (
      <Text
        fontSize="sm"
        color="white"
        bgColor="yellow.600"
        borderRadius="15px"
        px={2}
        pos="absolute"
        {...props}
      >
        {envName}
      </Text>
    )
  }

  return null
}
