import { VStack } from "@chakra-ui/layout"
import { FC } from "react"

import Sidebar, { SidebarProps } from "./Sidebar"
import UserNameDisplay from "./UserNameDisplay"

export interface SidebarContentProps
  extends Omit<SidebarProps, "topComponent" | "bottomComponent"> {
  additionalContent?: FC<SidebarContentProps>
  additionalFooter?: FC<SidebarContentProps>
}

const MainSidebar: FC<SidebarContentProps> = ({
  additionalContent: Content = () => null,
  additionalFooter: Footer = () => null,
  ...restProps
}) => {
  return (
    <Sidebar
      topComponent={
        <VStack align="start" spacing={10}>
          <UserNameDisplay />
          <Content {...restProps} />
        </VStack>
      }
      bottomComponent={<Footer {...restProps} />}
      {...restProps}
    />
  )
}

export default MainSidebar
