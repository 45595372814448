import { User } from "db"

import { useAuthContext } from "./AuthContext"

export const UserFlags = [
  "USER_LOADED",

  "SHOW_LAYMAN_SYMPTOMS",

  "IS_LAYMAN_USER",
  "IS_DISTRIBUTOR",
  "IS_EDITOR",
  "IS_CLINICAL",
  "IS_VIEWER",
  "IS_STAFF",
  "IS_EDITING_STAFF",
  "IS_SUPER_ADMIN",
] as const

export type UserFlag = typeof UserFlags[number]

type UserInformation = Pick<User, "role">

export function getUserFlags(
  user: UserInformation | null | undefined,
  isSuperAdmin: boolean = false
): Record<UserFlag, boolean> {
  if (!user) {
    return {
      USER_LOADED: false,

      SHOW_LAYMAN_SYMPTOMS: false,

      IS_LAYMAN_USER: false,
      IS_DISTRIBUTOR: true,
      IS_EDITOR: false,
      IS_CLINICAL: false,
      IS_VIEWER: false,
      IS_STAFF: false,
      IS_EDITING_STAFF: false,
      IS_SUPER_ADMIN: false,
    }
  }

  const { role } = user
  const isLaymanUser = role === "USER"
  const isDistributor = role === "DISTRIBUTOR"
  const isEditor = role === "EDITOR"
  const isClinical = role === "CLINICAL"
  const isViewer = role === "VIEWER"

  return {
    USER_LOADED: true,

    SHOW_LAYMAN_SYMPTOMS: isLaymanUser,

    IS_LAYMAN_USER: isLaymanUser,
    IS_DISTRIBUTOR: isDistributor,
    IS_EDITOR: isEditor,
    IS_CLINICAL: isClinical,
    IS_VIEWER: isViewer,
    IS_STAFF: isEditor || isViewer || isClinical,
    IS_EDITING_STAFF: isEditor || isClinical,
    IS_SUPER_ADMIN: isSuperAdmin && isEditor,
  }
}

export function useUserFlags(): Record<UserFlag, boolean> {
  const { currentUser } = useAuthContext()

  return getUserFlags(currentUser, currentUser?.isSuperAdmin)
}
