import { Box } from "@chakra-ui/react"
import { HEADER_HEIGHT_PX } from "app/common/config/constants"
import TwoFactorAuthWarning from "app/dashboard/components/TwoFactorAuthWarning"
import React, { FC } from "react"

type Props = {
  allowWithoutTwoFactorAuth?: boolean
}

const TwoFactorAuthBarrier: FC<Props> = ({ children, allowWithoutTwoFactorAuth }) => {
  return (
    <>
      <Box m={`${HEADER_HEIGHT_PX} 0 0 0`}>
        <TwoFactorAuthWarning />
      </Box>

      <Box px={4}>{allowWithoutTwoFactorAuth && children}</Box>
    </>
  )
}

export default TwoFactorAuthBarrier
