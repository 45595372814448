import { Box, BoxProps, Flex } from "@chakra-ui/layout"
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal"
import { FC } from "react"

import { useLocaleContext } from "../../../../utils/misc/LocaleContext"
import { CONTENT_HEIGHT, HEADER_HEIGHT_PX, SIDEBAR_WIDTH_PX } from "../../../config/constants"

export interface SidebarProps extends BoxProps {
  isOpen?: boolean
  onDrawerClose: () => void
  variant: "drawer" | "sidebar"
  topComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
}

const Sidebar: FC<SidebarProps> = ({
  variant,
  isOpen = false,
  onDrawerClose,
  topComponent,
  bottomComponent,
  ...restProps
}) => {
  const { isRTL } = useLocaleContext()
  const content = (
    <Flex direction="column" h="full" overflow="auto">
      {topComponent && <Box>{topComponent}</Box>}
      {bottomComponent && <Box mt="auto">{bottomComponent}</Box>}
    </Flex>
  )

  return variant === "sidebar" ? (
    <Box
      w={SIDEBAR_WIDTH_PX}
      h={CONTENT_HEIGHT}
      position="sticky"
      top={HEADER_HEIGHT_PX}
      insetStart={0}
      bg="gray.100"
      zIndex={5}
      {...restProps}
    >
      {content}
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement={isRTL ? "left" : "right"} onClose={onDrawerClose}>
      <DrawerOverlay>
        <DrawerContent bg="gray.100">
          <DrawerHeader />
          <DrawerCloseButton
            left={6}
            right="auto"
            _rtl={{ right: 6, left: "auto", justifyContent: "flex-end" }}
          />
          <DrawerBody>{content}</DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default Sidebar
