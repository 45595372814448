import { Flex, Link, Text } from "@chakra-ui/react"
import { useLogout } from "app/auth/hooks/useLogout"
import { useAuthContext } from "app/utils/misc/AuthContext"
import { Link as BlitzLink, Routes } from "blitz"
import { FC } from "react"
import { useTranslation } from "react-i18next"

import { ADMIN_GUIDE_LINK, USER_GUIDE_LINK } from "../../../../subjects/constants"
import { useUserFlags } from "../../../../utils/misc/userFlags"
import { SidebarContentProps } from "./MainSidebar"

const StandardFooter: FC<SidebarContentProps> = ({ onDrawerClose }) => {
  const { t } = useTranslation()
  const { currentUser } = useAuthContext()
  const { IS_DISTRIBUTOR, IS_STAFF } = useUserFlags()
  const logout = useLogout()

  if (!currentUser) {
    return null
  }

  return (
    <Flex px={4} mt={12} mb={6} flexDirection="column" color="brand.expertBlue">
      {IS_DISTRIBUTOR && (
        <>
          <BlitzLink href={Routes.DoctorsPage()} passHref>
            <Link onClick={onDrawerClose}>{t("actions.doctors")}</Link>
          </BlitzLink>
          <BlitzLink href={Routes.AssignedSamplesPage()} passHref>
            <Link onClick={onDrawerClose}>{t("actions.assignedSamplesPage")}</Link>
          </BlitzLink>
        </>
      )}

      <BlitzLink href={Routes.AccountPage()} passHref>
        <Link onClick={onDrawerClose}>{t("actions.account")}</Link>
      </BlitzLink>

      <Link onClick={logout} data-cy="logout-button">
        {t("actions.logout")}
      </Link>

      <Text fontSize="xs" mt={8}>
        <Link isExternal href={USER_GUIDE_LINK}>
          User guide
        </Link>
      </Text>

      {IS_STAFF && (
        <Text fontSize="xs">
          <Link isExternal href={ADMIN_GUIDE_LINK}>
            Internal user guide
          </Link>
        </Text>
      )}

      <Text fontSize="xs">arcensus portal v{process.env.NEXT_PUBLIC_PORTAL_VERSION}</Text>
    </Flex>
  )
}

export default StandardFooter
