import { Box, BoxProps, Container } from "@chakra-ui/layout"
import { useBreakpointValue } from "@chakra-ui/media-query"
import { useAuthContext } from "app/utils/misc/AuthContext"
import { useRouter } from "blitz"
import { FC, ReactNode, useEffect, useState } from "react"

import Head from "../../../core/components/Head"
import FullScreenLoader from "../../components/FullScreenLoader"
import {
  CONTAINER_MAX_WIDTH_PX,
  CONTENT_HEIGHT,
  CONTENT_WIDTH,
  HEADER_HEIGHT_PX,
} from "../../config/constants"
import Header from "./Header"
import MainSidebar, { SidebarContentProps } from "./Sidebar/MainSidebar"
import StandardFooter from "./Sidebar/StandardFooter"
import StandardSidebar from "./Sidebar/StandardSidebar"
import TwoFactorAuthBarrier from "./TwoFactorAuthBarrier"

export interface LayoutProps extends BoxProps {
  title?: string
  children: ReactNode
  hasSidebar?: boolean
  /** I used this for the admin views with their wide tables. Reponsiveness isn't important there. */
  unconstrainWidth?: boolean
  sidebarContent?: FC<SidebarContentProps>
  sidebarFooter?: FC<SidebarContentProps>
  allowWithoutTwoFactorAuth?: boolean
}

const Layout: FC<LayoutProps> = ({
  title,
  children,
  unconstrainWidth = false,
  sidebarContent = StandardSidebar,
  sidebarFooter = StandardFooter,
  allowWithoutTwoFactorAuth = false,
  ...restProps
}) => {
  const router = useRouter()
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)
  const onDrawerClose = () => setSidebarOpen(false)
  const sidebarVariant: "drawer" | "sidebar" =
    useBreakpointValue({ base: "drawer", md: "sidebar" }) || "sidebar"
  const { currentUser } = useAuthContext()
  const twoFactorAuthMissing =
    process.env.NEXT_PUBLIC_REQUIRE_TWO_FACTOR_AUTH && !currentUser?.twoFactorAuthEnabledAt

  useEffect(() => {
    if (!currentUser) {
      router.push("/login")
    }
  }, [currentUser, router])

  if (!currentUser) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Head title={title} />

      <Header onClickHamburger={toggleSidebar} />

      {twoFactorAuthMissing ? (
        <TwoFactorAuthBarrier allowWithoutTwoFactorAuth={allowWithoutTwoFactorAuth}>
          {children}
        </TwoFactorAuthBarrier>
      ) : (
        <Container
          maxW={unconstrainWidth ? "none" : CONTAINER_MAX_WIDTH_PX}
          minHeight={CONTENT_HEIGHT}
          m={`${HEADER_HEIGHT_PX} 0 0 0`}
          p={0}
          display="flex"
        >
          {sidebarVariant === "sidebar" && (
            <MainSidebar
              isOpen={isSidebarOpen}
              variant={sidebarVariant}
              onDrawerClose={onDrawerClose}
              additionalContent={sidebarContent}
              additionalFooter={sidebarFooter}
            />
          )}

          <Box
            w={unconstrainWidth ? "auto" : CONTENT_WIDTH}
            px={[3, null, 12]}
            py={10}
            {...restProps}
          >
            {children}
          </Box>

          {sidebarVariant !== "sidebar" && (
            <MainSidebar
              isOpen={isSidebarOpen}
              variant={sidebarVariant}
              onDrawerClose={onDrawerClose}
              additionalContent={sidebarContent}
              additionalFooter={sidebarFooter}
            />
          )}
        </Container>
      )}
    </>
  )
}

export default Layout
