import { Alert, AlertDescription, AlertIcon, AlertTitle, Link } from "@chakra-ui/react"
import { useLogout } from "app/auth/hooks/useLogout"
import { USER_GUIDE_LINK } from "app/subjects/constants"
import { useAuthContext } from "app/utils/misc/AuthContext"
import { Link as BlitzLink, Routes } from "blitz"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

type Props = {}

const TwoFactorAuthWarning: FC<Props> = ({}) => {
  const { t } = useTranslation()
  const { currentUser } = useAuthContext()
  const logout = useLogout()

  if (currentUser?.twoFactorAuthEnabledAt) {
    return null
  }

  return (
    <>
      <Alert
        status="error"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        mb={8}
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Your access is blocked because you do not have two-factor authentication enabled.
        </AlertTitle>
        <AlertDescription>
          Activate two-factor authentication in your account settings by clicking on this link:{" "}
          <BlitzLink href={Routes.AccountPage()} passHref>
            <Link color="expertBlue.500">Open account settings</Link>
          </BlitzLink>
        </AlertDescription>
        <AlertDescription>
          Open the user guide for more information:{" "}
          <Link color="expertBlue.500" isExternal href={USER_GUIDE_LINK}>
            Open user guide
          </Link>
        </AlertDescription>
        <AlertDescription>
          Click here to log out:{" "}
          <Link color="expertBlue.500" onClick={logout}>
            Logout
          </Link>
        </AlertDescription>
      </Alert>
    </>
  )
}

export default TwoFactorAuthWarning
