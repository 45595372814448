import { Box, Skeleton, Text } from "@chakra-ui/react"
import { useAuthContext } from "app/utils/misc/AuthContext"
import { FC } from "react"

const UserNameDisplay: FC = () => {
  const { currentUser } = useAuthContext()

  if (!currentUser) {
    return <UserNameDisplayLoader />
  }

  return (
    <Box w="100%" mt={6} px={4} color="brand.expertBlue">
      <Text fontWeight="bold" fontSize="lg">
        {currentUser.title} {currentUser.firstName} {currentUser.lastName}
      </Text>
      <Text data-cy="sidebar-user-email">{currentUser.email}</Text>
    </Box>
  )
}

const UserNameDisplayLoader = () => {
  return (
    <Box w="100%" mt={6} px={4}>
      <Skeleton height="16px" width="150px" />
      <Skeleton height="16px" width="200px" mt={2} />
    </Box>
  )
}

export default UserNameDisplay
