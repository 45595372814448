import { useMutation } from "blitz"

import logout from "../mutations/logout"

export function useLogout(): () => void {
  const [logoutMutation] = useMutation(logout)

  return () => {
    const reload = () => (location.href = "/")
    logoutMutation().then(reload).catch(reload)
  }
}
